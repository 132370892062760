/*!

=========================================================
* Black Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://progressnet.gr/product/black-dashboard-pro-react
* Copyright 2020 ProgressNet (https://progressnet.gr)

* Coded by ProgressNet

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
// reactstrap components
import {Container, Navbar, NavbarBrand,} from "reactstrap";
import allianzLogo from "assets/img/Allianz_logo_blue-700x181.png";

const AdminNavbar = ({brandText}) => {



  return (
      <>
        <Navbar
            className="fixed-top"
            expand="lg"
        >
          <Container fluid>
            <div className="navbar-wrapper">

              <div>
                <img style={{width: 250}} src={allianzLogo} alt="allianz-logo"/>
              </div>
            </div>
            <h2 className={"align-self-center m-0 ml-4"}>
              {brandText}
            </h2>
            <div style={{
              marginRight: 10,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'start',
              alignItems: 'center'
            }}>
              <NavbarBrand href="#pablo" onClick={(e) => e.preventDefault()}>
                Sergio Balbinot
              </NavbarBrand>
<div style={{width:200}}>

  <p style={{fontSize:12,color:'#acacac'}} >Member of the Board of Management of Allianz SE, Insurance Western & Southern Europe, Asia Pacific </p>
</div>
            </div>
          </Container>
        </Navbar>

      </>
  );
};

export default AdminNavbar;
