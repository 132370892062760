/*!

=========================================================
* Black Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://progressnet.gr/product/black-dashboard-pro-react
* Copyright 2020 ProgressNet (https://progressnet.gr)

* Coded by ProgressNet

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import AdminLayout from "layouts/Admin/Admin.js";
import "assets/css/nucleo-icons.css";
import "react-notification-alert/dist/animate.css";
import "assets/scss/black-dashboard-pro-react.scss?v=1.2.0";
import "assets/demo/demo.css";

ReactDOM.render(
    <AdminLayout/>,
    document.getElementById("root")
);
