/*!

=========================================================
* Black Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://progressnet.gr/product/black-dashboard-pro-react
* Copyright 2020 ProgressNet (https://progressnet.gr)

* Coded by ProgressNet

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useState} from "react";
import NotificationAlert from "react-notification-alert";

// core components
// import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footer/Footer.js";
import Dashboard from "../../views/Dashboard";
import Login from "../../views/pages/Login";

const Admin = (props) => {
  const mainPanelRef = React.useRef(null);
  const [loggedIn, setLoggedIn] = useState(false);




  return (
      <div className="wrapper">


        <div className="main-panel" ref={mainPanelRef}>
          {!loggedIn ?  <Login setLoggedIn={setLoggedIn}/>:<Dashboard /> }


          <Footer fluid />
        </div>
      </div>
  );
};

export default Admin;
