/*!

=========================================================
* Black Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https:/progressnet.gr/product/black-dashboard-pro-react
* Copyright 2020 ProgressNet (https://progressnet.gr)

* Coded by ProgressNet

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
} from "reactstrap";
import allianzLogo from "../../assets/img/Allianz_logo_blue-700x181.png";
import NotificationAlert from "react-notification-alert";

const Login = ({setLoggedIn}) => {
  const [state, setState] = React.useState({
    emailFocus:true,
    passFocus:'true',
    email: '',
    password: ''
  });
  const notificationAlertRef = React.useRef(null);
  React.useEffect(() => {
    document.body.classList.toggle("login-page");
    return function cleanup() {
      document.body.classList.toggle("login-page");
    };
  });

  const handleChange = (name,val) => {
    setState({...state, [name]:val})
  }
  const handleMiniClick = () => {
    if(state.email !== 'demo@allianz.com' && state.password !== 'demo!'){
      let notifyMessage = 'Please Check your Credentials!';
      let options = {};
      options = {
        place: "tc",
        message: notifyMessage,
        type: "primary",
        icon: "tim-icons icon-bell-55",
        autoDismiss: 7,
      };
      notificationAlertRef.current.notificationAlert(options);
    }else{
     
      setLoggedIn(true)
    }

  };
  return (
    <>
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <div className="content">
        <Container>
          <Col className="ml-auto mr-auto" lg="4" md="6">
            <Form className="form">
              <Card className="card-login card-white">
                <CardHeader >
                  <img
                    alt="..."
                    src={require("assets/img/card-primary.png").default}
                  />
                  <div className={' p-4'}>
                    <img style={{width: 250}} src={allianzLogo} alt="allianz-logo"/>
                  </div>
                </CardHeader>
                <CardBody>
                  <InputGroup
                    className={classnames({
                      "input-group-focus": state.emailFocus,
                    })}
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="tim-icons icon-email-85" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Email"
                      type="text"
                      value={state.email}
                      onChange={(e) => handleChange('email',e.target.value)}
                      // onFocus={(e) => setState({ ...state, emailFocus: true })}
                      // onBlur={(e) => setState({ ...state, emailFocus: false })}
                    />
                  </InputGroup>
                  <InputGroup
                    className={classnames({
                      "input-group-focus": state.passFocus,
                    })}
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="tim-icons icon-lock-circle" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Password"
                      type="text"
                      value={state.password}
                      onChange={(e) => handleChange('password',e.target.value)}
                      onFocus={(e) => setState({ ...state, passFocus: true })}
                      onBlur={(e) => setState({ ...state, passFocus: false })}
                    />
                  </InputGroup>
                </CardBody>
                <CardFooter>
                  <Button
                    block
                    className="mb-3"
                    color="primary"
                    href="#pablo"
                    onClick={(e) => handleMiniClick()}
                    size="lg"
                  >
                    Get Started
                  </Button>
                  {/*<div className="pull-left">*/}
                  {/*  <h6>*/}
                  {/*    <a*/}
                  {/*      className="link footer-link"*/}
                  {/*      href="#pablo"*/}
                  {/*      onClick={(e) => e.preventDefault()}*/}
                  {/*    >*/}
                  {/*      Create Account*/}
                  {/*    </a>*/}
                  {/*  </h6>*/}
                  {/*</div>*/}
                  {/*<div className="pull-right">*/}
                  {/*  <h6>*/}
                  {/*    <a*/}
                  {/*      className="link footer-link"*/}
                  {/*      href="#pablo"*/}
                  {/*      onClick={(e) => e.preventDefault()}*/}
                  {/*    >*/}
                  {/*      Need Help?*/}
                  {/*    </a>*/}
                  {/*  </h6>*/}
                  {/*</div>*/}
                </CardFooter>
              </Card>
            </Form>
          </Col>
        </Container>
      </div>
    </>
  );
};

export default Login;
