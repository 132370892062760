export const findUnique = (data, field) => {
  let res = [];
  if (data?.length) {
    res = [
      ...data
      ?.reduce((a, c) => {
        a.set(c[field], c);
        return a;
      }, new Map())
      .values(),
    ];
  }

  return res.filter((el) => el[field] !== null);
};
