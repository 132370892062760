/*!

=========================================================
* Black Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://progressnet.gr/product/black-dashboard-pro-react
* Copyright 2020 ProgressNet (https://progressnet.gr)

* Coded by ProgressNet

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useEffect, useMemo, useRef, useState} from "react";
import {Bar} from "react-chartjs-2";
import GaugeChart from "react-gauge-chart";
import * as d3 from "d3";
import {Button, Card, CardBody, CardHeader, Col, Collapse, Row} from "reactstrap";
import Globe from "react-globe.gl";
import countries from "../variables/countries.json";
import {findUnique} from "../variables/helpers";
import AdminNavbar from "../components/Navbars/AdminNavbar";
import StreetLight from "../components/Svg/StreetLight";


const Dashboard = () => {
  const [selected, setSelected] = useState(null)
  const [entity, setEntity] = useState(null);
  const [gauge, setGauge] = useState('')
  const [isOpen, setIsOpen] = useState(0);
  const [hoverD, setHoverD] = useState();
  const globeEl = useRef()

  useEffect(() => {

    // Auto-rotate
    if (globeEl) {
      globeEl.current.controls().autoRotate = true;
      globeEl.current.controls().autoRotateSpeed = 0.1;

    }

  }, []);



  const colorScale = d3.scaleSequentialSqrt(d3.interpolateYlOrRd);

  const getVal = (feat) =>
      feat.properties.GDP_MD_EST / Math.max(1e5, feat.properties.POP_EST);

  const maxVal = useMemo(() => Math.max(...countries.features.map(getVal)), []);
  colorScale.domain([0, maxVal]);


  const draft = [
    {category: 'CAT I', description: 'AZ Deutschland AG	', country: 'Germany'},
    {category: 'CAT I', description: 'AZ France	', country: 'France'},
    {category: 'CAT II', description: 'AZ Benelux	', country: 'Netherlands'},
    {category: 'CAT II', description: 'AZ Spain	', country: 'Spain'},
    {category: 'CAT III', description: 'AZ China General Insurance Co. Ltd.	', country: 'China'},
    {category: 'CAT III', description: 'AZ Malaysia Berhad	', country: 'Malaysia'},
  ]
  const barOther = {
    labels: ['Asset Management', 'Banking', 'Pension', 'Branch Audit/CE Level', 'General Review', 'Others'],
    datasets: [
      {
        label: '',
        data: [18, 15, 6, 25, 4, 8],
        backgroundColor: [
          'rgba(255, 99, 132, 0.4)',
          'rgba(54, 162, 235, 0.4)',
          'rgba(255, 206, 86, 0.4)',
          'rgba(75, 192, 192, 0.4)',
          'rgba(153, 102, 255, 0.4)',
          'rgba(255, 159, 64, 0.4)',
        ],
        // borderColor: [
        //   'rgba(255, 99, 132, 1)',
        //   'rgba(54, 162, 235, 1)',
        //   'rgba(255, 206, 86, 1)',
        //   'rgba(75, 192, 192, 1)',
        //   'rgba(153, 102, 255, 1)',
        //   'rgba(255, 159, 64, 1)',
        // ],
        borderWidth: 0.2,
      },
    ],
  };
  const barCentral = {
    labels: ['Finance-Accounting', 'Finance-Risk Mgt', 'Finance-Other'],
    datasets: [
      {
        label: '',
        data: [56, 35, 60],
        backgroundColor: [
          'rgba(54, 162, 235, 0.4)',
          'rgba(255, 159, 64, 0.4)',
          'rgba(153, 102, 255, 0.4)',

        ],
        // borderColor: [
        //   'rgba(255, 99, 132, 1)',
        //   'rgba(54, 162, 235, 1)',
        //   'rgba(255, 206, 86, 1)',
        //
        // ],
        borderWidth: 0.2,
      },
    ],
  };

  const barOperations = {
    labels: ['Policy Mgt/Underwriting', 'Claims Management', 'Information Technology', 'Operations Support', 'Organizational Mgt.'],
    datasets: [
      {
        label: '',
        data: [46, 74, 101, 62, 24],
        backgroundColor: [
          'rgba(54, 162, 235, 0.4)',
          'rgba(255, 159, 64, 0.4)',
          'rgba(153, 102, 255, 0.4)',
          'rgba(75, 192, 192, 0.4)',
          'rgba(153, 102, 255, 0.4)',


        ],
        // borderColor: [
        //   'rgba(255, 99, 132, 1)',
        //   'rgba(54, 162, 235, 1)',
        //   'rgba(255, 206, 86, 1)',
        //   'rgba(75, 192, 192, 1)',
        //   'rgba(153, 102, 255, 1)',
        //
        // ],
        borderWidth: 0.2,
      },
    ],
  };

  const handleRow = (c, e) => {
    const found = countries.features.find(el => el.properties.ADMIN === c);
    setEntity(e)
    if (found) {
      setSelected(found)
      globeEl.current.pointOfView(getCenter(found.geometry?.coordinates[0]), 1000)
    }

  }
  const optionsOther = {
    indexAxis: 'y',
    // Elements options apply to all of the options unless overridden in a dataset
    // In this case, we are setting the border of each horizontal bar to be 2px wide
    elements: {
      bar: {
        borderWidth: 2,
        display: true
      },
    },
    responsive: true,
    plugins: {
      labels: {
        color: 'white'
      },
      legend: {
        display: false,
        position: 'right',
      },
      title: {
        display: true,
        text: 'Other Areas',
        color:'#b8b8b8'
      },
    },

  };
  const optionsCentral = {
    indexAxis: 'y',
    // Elements options apply to all of the options unless overridden in a dataset
    // In this case, we are setting the border of each horizontal bar to be 2px wide
    elements: {
      bar: {
        borderWidth: 2,
        display: true
      },
    },
    responsive: true,
    plugins: {
      labels: {
        color: 'white'
      },
      legend: {
        display: false,
        position: 'right',
      },
      title: {
        display: true,
        text: 'Central Functions',
        color:'#b8b8b8'
      },
    },

  };

  const optionsOper = {
    indexAxis: 'y',
    // Elements options apply to all of the options unless overridden in a dataset
    // In this case, we are setting the border of each horizontal bar to be 2px wide
    elements: {
      bar: {
        borderWidth: 2,
        display: true,

      },
      labels: {
        color:'fff'
      }
    },

    scales: {
      xAxes: [{
        stacked: true
      }],
      yAxes: [{
        stacked: true,
        color:'#fff'
      }]
    } ,
    responsive: true,
    plugins: {
      labels: {
        color: '#fff'
      },

      datalabels: {
        color: '#fff',
        display: function(context) {
          console.log('con',context)
          return context.dataset.backgroundColor;
        },
        font: {
          weight: 'bold'
        },
        formatter: Math.round
      },
      legend: {
        display: false,
        position: 'right',
        labels:{
          color:'#fff'
        }
      },
      scales: {
        yAxes: [{
          ticks: {
            fontColor: "green",
            fontSize: 18,
            stepSize: 1,
            beginAtZero: true
          }
        }],
        xAxes: [{
          ticks: {
            fontColor: "purple",
            fontSize: 14,
            stepSize: 1,
            beginAtZero: true
          }
        }]
      },
      title: {
        text: 'Operations',
        display: true,
        color:'#b8b8b8'
      },
    },

  };

  const getCenter = (arrMap) => {
    const arr = arrMap[0].length === 2 ? arrMap : arrMap[0];

    const x = arr.map(function (a) {
      return a[0]
    });
    const y = arr.map(function (a) {
      return a[1]
    });
    const minX = Math.min.apply(null, x);
    const maxX = Math.max.apply(null, x);
    const minY = Math.min.apply(null, y);
    const maxY = Math.max.apply(null, y);
    const coords = [(minX + maxX) / 2, (minY + maxY) / 2];
    return {lat: coords[1], lng: coords[0], altitude: 1.6}
  }

  return (
      <>
        <AdminNavbar
            brandText={selected && `${selected?.properties?.ADMIN}`}
            sidebarOpened={false}
        />
        <div className="content mt-3">
          <Row>
            <Col lg="12">
              <Card>

                <CardBody>
                  <Row>
                    <Col className="ml-auto mr-auto" style={{overflow: "hidden"}}>
                      <Globe
                          ref={globeEl}
                          globeImageUrl="//unpkg.com/three-globe/example/img/earth-night.jpg"
                          backgroundImageUrl="//unpkg.com/three-globe/example/img/night-sky.png"
                          lineHoverPrecision={0}
                          polygonsData={countries.features.filter(
                              (d) => d.properties.ISO_A2 !== "AQ"
                          )}
                          polygonAltitude={(d) => (d === selected ? 0.12 : 0.06)}
                          polygonCapColor={(d) =>
                              d === selected ? "steelblue" : colorScale(getVal(d))
                          }
                          polygonSideColor={() => "rgba(0, 100, 0, 0.15)"}
                          polygonStrokeColor={(d) => (d === hoverD ? 'steelblue' : '#111')}
                          polygonLabel={({properties: d}) => `<div style="background-color: rgba(12,84,96,0.8);border-radius: 3px;padding:10px">
                      <b>${d.ADMIN} (${d.ISO_A2})</b> <br />
                      ${d.Entity ? `<b>CAT ${d.Cat}</b>: ${d.Entity}` : ''}
                    </div>`}
                          // onPolygonHover={setHoverD}
                          onPolygonClick={(e) => {
                            setSelected(e);
                            globeEl.current.pointOfView(getCenter(e.geometry?.coordinates[0]), 1000)
                          }}
                          polygonsTransitionDuration={300}
                          height={750}
                          width={window.innerWidth}
                      />


                    </Col>
                    <Row style={{position: "absolute", width: 500}}>

                      <Col>
                        <Card className="custom-card-chart" style={{backgroundColor: 'transparent'}}>

                          <CardBody>
                            <h4 className={'ml-3  p-0'}>AUDIT RESULTS PER FUNCTIONAL AREA 2020</h4>
                            <div className="chart-area">
                              <Bar data={barCentral} options={optionsCentral}/>
                            </div>

                            <div className="chart-area">
                              <Bar data={barOperations} options={optionsOper}/>
                            </div>
                            <div className="chart-area">
                              <Bar data={barOther} options={optionsOther}/>
                            </div>
                          </CardBody>
                        </Card>

                      </Col>

                    </Row>
                    <Col lg="3" style={{position: "absolute", right: 10, height: 700, overflow: 'hidden'}}>
                      <Row style={{marginBottom: 10, alignItems: 'center', justifyContent: 'center'}}>
                        {/*<div className="chart-area" style={{width: 200}}>*/}
                        {/*  <Doughnut data={doughnatCat}/>*/}
                        {/*</div>*/}


                      </Row>
                      <table className="table">
                        <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Category</th>

                        </tr>
                        </thead>
                        <tbody>
                        {findUnique(draft, 'category').map((el, i) => {
                          return <tr key={i}>
                            <th scope="row"><Button
                                onClick={() => setIsOpen(isOpen === el.category ? false : el.category)}
                                style={{color: '#acacac'}} color="link">   {i + 1} </Button></th>
                            <td><Button onClick={() => setIsOpen(isOpen === el.category ? false : el.category)}
                                        style={{color: '#acacac'}}
                                        color="link">{` ${isOpen === el.category ? '' : '👉'} ${el.category} `} </Button>
                            </td>
                            <td><Collapse isOpen={isOpen === el.category}>
                              <Card style={{backgroundColor: 'rgba(50,54,76,0.5)'}}>
                                <CardBody>
                                  {draft.filter(ef => ef.category === isOpen)
                                  .map((ct, i) => {
                                        return <div key={i}>
                                          <Button onClick={() => handleRow(ct.country, ct.description)} style={{
                                            color: '#acacac',
                                            paddingTop: 3,
                                            paddingBottom: 3,
                                            width: '100%',
                                            marginBottom: 5
                                          }} color="secondary">{ct.description}</Button>
                                        </div>
                                      }
                                  )}

                                </CardBody>
                              </Card>
                            </Collapse></td>

                          </tr>
                        })}


                        </tbody>
                      </table>

                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <div className="navbar-wrapper d-flex">

            <div className=" d-flex ">
              <h2 className={"align-self-center m-0 ml-4"}>
                {entity}
              </h2>
            </div>
          </div>
          <Row>

            <Col
                className={"d-flex flex-column justify-content-center mt-4"}
                xs="6"
            >


              <Row
                  className={
                    "d-flex justify-content-center align-items-center bg-gradient-blue p-1 w-100 m-0"
                  }
              >
                <h3 className="mb-0">Background</h3>
              </Row>
              <Col xs={12}>
                <p className="pt-4">
                  Contrary to popular belief, Lorem Ipsum is not simply random
                  text. It has roots in a piece of classical Latin literature from
                  45 BC, making it over 2000 years old. Richard McClintock, a
                  Latin professor at Hampden-Sydney College in Virginia, looked up
                  one of the more obscure Latin words, consectetur, from a Lorem
                  Ipsum passage, and going through the cites of the word in
                  classical literature, discovered the undoubtable source. Lorem
                  Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus
                  Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero,
                  written in 45 BC. This book is a treatise on the theory of
                  ethics, very popular during the Renaissance. The first line of
                  Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line
                  in section 1.10.32. The standard chunk of Lorem Ipsum used since
                  the 1500s is reproduced below for those interested. Sections
                  1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by
                  Cicero are also reproduced in their exact original form,
                  accompanied by English versions from the 1914 translation by H.
                  Rackham.
                </p>
              </Col>
            </Col>
            <Col
                className={"d-flex flex-column justify-content-center mt-1"}
                xs="6"
            >


              <Row
                  className={
                    "d-flex justify-content-center align-items-center bg-gradient-blue p-1 w-100 m-0"
                  }
              >
                <h3 className="mb-0">Summary Opinion</h3>
              </Row>
              <div className="navbar-wrapper d-flex justify-content-center align-items-center mb-4 ">

                <div className={'d-flex flex-column justify-content-center align-items-center p-2'}>
                  <p>Group View</p>
                  <StreetLight width={200} height={200} o={"#ffe62e"} r={"#383838"}
                               g={"#383838"}/> {/* o={"#ffe62e"} r={"#ed4c5c"} g={"#83bf4f"}*/}

                </div>
                <div className={'d-flex flex-column justify-content-center align-items-center p-2'}>
                  <p>Local View</p>
                  <StreetLight width={200} height={200} o={"#383838"} r={"#383838"} g={"#83bf4f"}/>

                </div>

              </div>
              <Row>
                <Col xs="3" onClick={() => setGauge('Governace text')}
                     className={'d-flex flex-column justify-content-center align-items-center'} style={{cursor:'pointer'}}>
                  <GaugeChart
                      id="gauge-chart1"
                      nrOfLevels={40}
                      // arcsLength={[0.6, 0.3, 0.1]}
                      colors={Array.from({length: 40}, (_, i) => {
                        if (i < 10) return "#ff1a00";
                        if (i < 20) return "#f59753";
                        if (i < 30) return "#f8f82e";
                        if (i < 40) return "#23a18d";
                      })}
                      arcWidth={0.3}
                      percent={0.48}
                      arcPadding={0.03}
                      needleColor={"#8a9391"}
                      hideText={true}
                  />
                  <p style={{textAlign: 'center'}}>Governance</p>
                </Col>
                <Col xs="3" onClick={() => setGauge('Implementation of NPS process')}
                     className={'d-flex flex-column justify-content-center align-items-center'} style={{cursor:'pointer'}}>

                  <GaugeChart

                      id="gauge-chart2"
                      nrOfLevels={40}
                      // arcsLength={[0.6, 0.3, 0.1]}
                      colors={Array.from({length: 40}, (_, i) => {
                        if (i < 10) return "#ff1a00";
                        if (i < 20) return "#f59753";
                        if (i < 30) return "#f8f82e";
                        if (i < 40) return "#23a18d";
                      })}
                      arcWidth={0.3}
                      percent={0.77}
                      arcPadding={0.03}
                      needleColor={"#8a9391"}
                      hideText={true}
                  />
                  <p style={{textAlign: 'center'}}>Implementation of NPS process</p>

                </Col>
                <Col xs="3" onClick={() => setGauge('Conclusions and Implementation of actions')}
                     className={'d-flex flex-column justify-content-center align-items-center'} style={{cursor:'pointer'}}>

                  <GaugeChart
                      id="gauge-chart3"
                      nrOfLevels={40}
                      // arcsLength={[0.6, 0.3, 0.1]}
                      colors={Array.from({length: 40}, (_, i) => {
                        if (i < 10) return "#ff1a00";
                        if (i < 20) return "#f59753";
                        if (i < 30) return "#f8f82e";
                        if (i < 40) return "#23a18d";
                      })}
                      arcWidth={0.3}
                      percent={0.95}
                      arcPadding={0.03}
                      needleColor={"#8a9391"}
                      hideText={true}


                  />


                  <p style={{textAlign: 'center'}}>Conclusions and Implementation of actions</p>


                </Col>
                <Col xs="3">
                  <div className="my-legend">
                    <div className="legend-title">Legend</div>
                    <div className="legend-scale">
                      <ul className="legend-labels">
                        <li>
                          <span style={{backgroundColor: "#23a18d"}}></span>S
                          (Satisfactory)
                        </li>
                        <li>
                          <span style={{backgroundColor: "#f8f82e"}}></span>I
                          (Improvement Needed)
                        </li>
                        <li>
                          <span style={{backgroundColor: "#f59753"}}></span>SI
                          (Significant Improvement Needed)
                        </li>
                        <li>
                          <span style={{backgroundColor: "#ff1a00"}}></span>U
                          (Unsatisfactory)
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>

              </Row>
              <Collapse isOpen={gauge.length ? true : false}>
                <div className={'d-flex flex-column justify-content-center align-items-center w-100'}>
                  <Card >
                    <CardHeader className={'p-0'}>
                      <Row
                          className={
                            "d-flex justify-content-center align-items-center bg-gradient-blue p-1 w-100 m-0"
                          }
                          style={{borderTopLeftRadius:7,borderTopRightRadius:7}}
                      >
                        <h4 className="mb-0">Summary of {gauge}</h4>
                      </Row>
                    </CardHeader>
                    <CardBody className={'w-100'}>

                      <p>Contrary to popular belief, Lorem Ipsum is not simply random
                        text. It has roots in a piece of classical Latin literature from
                        45 BC, making it over 2000 years old. Richard McClintock</p>
                    </CardBody>
                  </Card>
                  <Card style={{width:'100%'}}>
                    <CardHeader  className={'w-100'}>
                      <Row
                          className={
                            "d-flex justify-content-center align-items-center bg-gradient-blue p-1 w-100 mt-3"
                          }
                          style={{borderTopLeftRadius:7,borderTopRightRadius:7}}
                      >
                        <h4 className="mb-0">Recommendation for {gauge}</h4>
                      </Row>
                    </CardHeader>
                    <CardBody className={'w-100'}>

                      <p>Contrary to popular belief, Lorem Ipsum is not simply random
                        text. It has roots in a piece of classical Latin literature from
                        45 BC, making it over 2000 years old. Richard McClintock</p>
                    </CardBody>
                  </Card>
                </div>

              </Collapse>
            </Col>
          </Row>
        </div>
      </>
  );
};

export default Dashboard;
