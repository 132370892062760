import React from "react"

function StreetLight(props) {
  return (
      <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
        <path
            d="M32 2c-8.2 0-15 6.8-15 15v30c0 8.3 6.8 15 15 15s15-6.7 15-15V17c0-8.2-6.8-15-15-15"
            fill={"#94989b"}
        />
        <path
            d="M32 3.9c-7.2 0-13.1 5.9-13.1 13.1v30c0 7.2 5.9 13.1 13.1 13.1S45.1 54.2 45.1 47V17c0-7.2-5.9-13.1-13.1-13.1"
            fill={"#3e4347"}
        />
        <circle cx={32} cy={32} r={7.5} fill={props.o} />
        <circle cx={32} cy={13.2} r={7.5} fill={props.r} />
        <circle cx={32} cy={50.8} r={7.5} fill={props.g} />
      </svg>
  )
}

export default StreetLight;
