/*!

=========================================================
* Black Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://progressnet.gr/product/black-dashboard-pro-react
* Copyright 2020 ProgressNet (https://progressnet.gr)

* Coded by ProgressNet

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
import { Container, Row } from "reactstrap";
// used for making the prop types of this component
import PropTypes from "prop-types";

const Footer = (props) => {
  return (
    <footer className={"footer" + (props.default ? " footer-default" : "")}>
      <Container fluid={props.fluid ? true : false}>
        {/*<ul className="nav">*/}
        {/*  <li className="nav-item">*/}
        {/*    <a className="nav-link" href="https://progressnet.gr">*/}
        {/*      ProgressNet*/}
        {/*    </a>*/}
        {/*  </li>{" "}*/}

        {/*</ul>*/}
        <div className="copyright">
          © {new Date().getFullYear()} {" "}
          <a href="#" target="_blank">
            Intelligence Center
          </a>
        </div>
      </Container>
    </footer>
  );
};

Footer.propTypes = {
  default: PropTypes.bool,
  fluid: PropTypes.bool,
};

export default Footer;
